import React, { useState, Fragment } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import useUpdateCart from '../../Hooks/useUpdateCart/useUpdateCart';
import Toast from '../../Utils/toast';
import useUserData from '../../Hooks/useUser/useUserdata';
import { Link } from 'react-router-dom';
import { IconButton, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import Remove from '@mui/icons-material/Remove';
import { Add, Delete } from '@mui/icons-material';

const CartProduct = (props) => {
    const [isAddedTocart, setIsAddedTocart] = useState(false);
    const { product } = props;
    const [cartData, addProduct, removeProduct, decreaseProductQuantity, increaseProductQuantity] = useUpdateCart();
    const [, , , isUserAutheticated, getCurrentUserData] = useUserData();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const convertNumberFormat = (number) => {
        return number.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'INR',
        });
    };
    const handleProductActions = (type, product) => {
        const userDataObj = isUserAutheticated() ? getCurrentUserData() : null;
        switch (type) {
            case 'increase':
                increaseProductQuantity([cartData.products, product, userDataObj.uid]);
                break;
            case 'decrease':
                decreaseProductQuantity([cartData.products, product, userDataObj.uid]);
                break;
            default:
                removeProduct([cartData.products, product, userDataObj.uid]);
        }
    }

    const handleRemoveProduct = (product) => {
        if (isUserAutheticated() == true) {
            const userDataObj = getCurrentUserData();
            removeProduct([cartData.products, product, userDataObj.uid]);
        }
    }

    const handleDecreaseProductQuantity = (product) => {
        if (isUserAutheticated() == true) {
            const userDataObj = getCurrentUserData();
            decreaseProductQuantity([cartData.products, product, userDataObj.uid]);
        }

    }

    const handleIncreaseProductQuantity = (product) => {
        if (isUserAutheticated() == true) {
            const userDataObj = getCurrentUserData();
            increaseProductQuantity([cartData.products, product, userDataObj.uid]);
        }
    }

    return <Fragment>
        <Link to={product.productPage} key={product.id} style={{ textDecoration: 'none' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row', // Ensure items are in a single line
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 2,
                    padding: 2,
                    border: '1px solid #ccc',
                    borderRadius: 1,
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Box shadow added
                    transition: 'box-shadow 0.3s ease',
                    '&:hover': {
                        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)', // Hover effect
                    },
                    flexWrap: 'wrap', // Allow wrapping to prevent overflow
                }}
            >
                {/* Product Image and Details */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <img
                        src={product.images[0]}
                        alt={product.title}
                        style={{
                            height: '80px',
                            borderRadius: '5px',
                            objectFit: "contain"
                        }}
                    />
                    <Box>
                        <Typography variant="h6">{product.title}</Typography>
                        <Typography variant="body2" color="text.secondary">
                            Price: {convertNumberFormat(product.price)}
                        </Typography>
                    </Box>
                </Box>

                {/* Quantity Controls */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <IconButton color="primary" onClick={() => { handleProductActions('decrease', product) }} disabled={product.quantity <= 1}>

                        <Remove />
                    </IconButton>
                    <TextField
                        value={product.quantity}
                        variant="outlined"
                        size="small"
                        inputProps={{
                            readOnly: true,
                            style: { textAlign: 'center', width: 40 },
                        }}
                    />
                    <IconButton color="primary" onClick={() => { handleProductActions('increase', product) }}>
                        <Add />
                    </IconButton>
                </Box>

                {/* Item Total Price */}
                <Box>
                    <Typography>{convertNumberFormat((product.price * product.quantity))}</Typography>
                </Box>

                {/* Remove Button */}
                <Box sx={{ marginTop: isMobile ? '8px' : '0' }}>
                    <IconButton
                        color="error"
                        onClick={() => { handleProductActions('remove', product) }}
                        sx={{
                            fontSize: isMobile ? '18px' : '24px', // Smaller delete icon on mobile
                            padding: '4px',
                        }}
                    >
                        <Delete />
                    </IconButton>
                </Box>
            </Box>
        </Link>
        <Toast open={isAddedTocart} message={'Product Added To Cart!'} type={'success'} />
    </Fragment >

}

export default CartProduct;