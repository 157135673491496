import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Box, Drawer, Badge } from '@mui/material';
import { Menu as MenuIcon, AccountCircle, ShoppingCart } from '@mui/icons-material';
import { useTheme } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { NavLink, useNavigate } from 'react-router-dom';
import useUserData from '../../Hooks/useUser/useUserdata';

import { useSelector } from 'react-redux';
import useUpdateCart from '../../Hooks/useUpdateCart/useUpdateCart';

const Header = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [userData, showLoginDrawer, hideLoginDrawer, isUserAutheticated, getCurrentUserData, logout] = useUserData();
    const [, , , ,
        ,
        , resetCartData] = useUpdateCart();
    const result = useSelector(state => state.useUpdateCartReducer);
    const navigate = useNavigate();



    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const handleLogout = () => {
        handleMenuClose();
        resetCartData();
        logout();
        navigate("/");

    }

    return (
        <AppBar
            position="sticky"
            sx={{ backgroundColor: 'white', boxShadow: 3 }}
        >
            <div className="help-section">
                <div className="container">
                    <div className="row no-gutters d-flex align-items-start align-items-center px-md-0">
                        <div className="col-lg-12 d-block">
                            <div className="row d-flex">
                                <div className="col-md pr-4 d-flex topper align-items-center">
                                    <div className="icon mr-2 d-flex justify-content-center align-items-center"><span
                                        className="icon-phone2"></span></div>
                                    <span className="text">+ 8121298899</span>
                                </div>
                                <div className="col-md pr-4 d-flex topper align-items-center">
                                    <div className="icon mr-2 d-flex justify-content-center align-items-center"><span
                                        className="icon-paper-plane"></span></div>
                                    <span className="text">support@hygienebites.com</span>
                                </div>
                                <div className="col-md-5 pr-4 d-flex topper align-items-center text-lg-right">
                                    <span className="text">Same day delivery &amp; Free Returns</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toolbar>
                {/* Logo Section */}
                <Box sx={{ display: 'flex', flexGrow: 1, color: '#d99308' }}>
                    {/* <img
                        src="https://via.placeholder.com/150"
                        alt="Logo"
                        style={{ height: '40px', objectFit: 'contain' }}
                    /> */}

                    <NavLink to="/"
                        style={{ color: '#d99308' }}
                        className={({ isActive }) => (isActive ? "navbar-brand" : 'navbar-brand')}
                    >
                        Hygiene Bites
                    </NavLink>

                </Box>

                {/* Desktop Navigation */}
                {!isMobile && (
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <NavLink to="/" className="nav-link" activeClassName="active-nav-link">Home</NavLink>
                        <NavLink to="/products" className="nav-link" activeClassName="active-nav-link">Products</NavLink>
                        <NavLink to="/about" className="nav-link" activeClassName="active-nav-link">About</NavLink>
                        <NavLink to="/contact" className="nav-link" activeClassName="active-nav-link">Contact</NavLink>
                    </Box>
                )}

                {/* Cart Icon */}


                <NavLink to="/cart" style={{ textDecoration: 'none' }}>
                    <IconButton size="large" color="inherit" sx={{ color: 'black', marginRight: 2 }}>
                        <Badge badgeContent={result.count} color="primary">
                            <ShoppingCart />
                        </Badge>
                    </IconButton>
                </NavLink>


                {/* User Profile */}
                {
                    isUserAutheticated() ? (
                        <div>
                            <IconButton
                                size="large"
                                aria-controls="user-menu"
                                aria-haspopup="true"
                                onClick={handleMenuOpen}
                                color="inherit"
                                sx={{ color: 'black' }}
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="user-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={handleMenuClose}><li className="nav-item">
                                    <NavLink className="nav-link" to="/profile">
                                        Profile
                                    </NavLink>
                                </li></MenuItem>
                                <MenuItem onClick={handleMenuClose}><li className="nav-item">
                                    <NavLink className="nav-link" to="/orders">
                                        Orders
                                    </NavLink>
                                </li></MenuItem>
                                <MenuItem onClick={handleMenuClose}>
                                    <li className="nav-item">
                                        <a className="nav-link" href="javascript:void(0)" onClick={() => { handleLogout() }}>
                                            Logout
                                        </a>
                                    </li></MenuItem>
                            </Menu>
                        </div>
                    ) : (
                        <button onClick={showLoginDrawer} className="nav-link">Login</button>
                    )
                }

                {/* Mobile Menu Button */}
                {
                    isMobile && (
                        <IconButton
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleMobileMenu}
                            sx={{ color: 'black' }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )
                }
            </Toolbar >

            {/* Mobile Drawer */}
            < Drawer
                anchor="right"
                open={mobileMenuOpen}
                onClose={toggleMobileMenu}
            >
                <Box
                    sx={{
                        width: 250,
                        padding: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <NavLink to="/" className="nav-link" activeClassName="active-nav-link" onClick={toggleMobileMenu}>
                        Home
                    </NavLink>
                    <NavLink to="/products" className="nav-link" activeClassName="active-nav-link" onClick={toggleMobileMenu}>
                        Products
                    </NavLink>
                    <NavLink to="/about" className="nav-link" activeClassName="active-nav-link" onClick={toggleMobileMenu}>
                        About
                    </NavLink>
                    <NavLink to="/contact" className="nav-link" activeClassName="active-nav-link" onClick={toggleMobileMenu}>
                        Contact
                    </NavLink>
                </Box>
            </Drawer >
        </AppBar >
    );
};

export default Header;