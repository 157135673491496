import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Grid,
    Button,
    IconButton,
    Card,
    CardMedia,
    CardContent,
    Modal,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { AddShoppingCart } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import useProducts from '../../Hooks/useProducts/useProducts';
import LoadingSpinner from '../../Utils/loader';

const ProductDetailPage = () => {
    const { productId } = useParams(); // Get the productId from the URL

    const [product, setProduct] = useState(null); // Store product details
    const [selectedImage, setSelectedImage] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [cartCount, setCartCount] = useState(1); // Quantity for the cart

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [productsPageData, getProducts] = useProducts();
    const { products } = productsPageData

    // Mock function to fetch product details by ID
    const fetchProductById = (id) => {
        console.log(products);
        const product = products.find((product) => product.id == id);

        return product
    };

    // Fetch product details on mount
    useEffect(() => {
        //if (!products.length) {
        getProducts();
        //}

    }, []);
    useEffect(() => {
        const fetchedProduct = fetchProductById(productId);
        console.log(fetchedProduct)
        if (fetchedProduct) {
            setProduct(fetchedProduct);
            setSelectedImage(fetchedProduct.images[0]); // Set the first image as default
        }
    }, [products]);

    // Handle the image selection
    const handleImageSelect = (image) => {
        setSelectedImage(image);
    };

    // Handle Add to Cart action
    const handleAddToCart = () => {
        console.log(`Added ${cartCount} items of ${product?.name} to the cart`);
    };

    // Modal for viewing large image
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    if (!product) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <Box sx={{ padding: 2 }}>
            <Grid container spacing={3}>
                {/* Product Image Section */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardMedia
                            component="img"
                            image={selectedImage}
                            alt={product.name}
                            sx={{
                                height: isMobile ? '300px' : '500px',
                                cursor: 'pointer', objectFit: 'contain',
                            }
                            }
                            onClick={handleOpenModal} // Open Modal for enlarged image
                        />
                    </Card>

                    {/* Image Thumbnails */}
                    <Grid container spacing={1} sx={{ marginTop: 1 }}>
                        {product.images.map((image, index) => (
                            <Grid item key={index} xs={3} sm={2} md={1}>
                                <Card
                                    onClick={() => handleImageSelect(image)}
                                    sx={{
                                        cursor: 'pointer',
                                        border: selectedImage === image ? '2px solid #1976d2' : 'none',
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        image={image}
                                        alt={`Thumbnail ${index}`}
                                        sx={{ height: 70, objectFit: 'contain' }}
                                    />
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                {/* Product Details Section */}
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                        {product.title}
                    </Typography>
                    <Typography variant="body1" sx={{ marginY: 2 }}>
                        {product.description}
                    </Typography>
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>
                        {`Price: ₹${product.price.toLocaleString()}`}
                    </Typography>

                    {/* Quantity Selector */}
                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                        <Grid item xs={4}>
                            <Typography variant="body1">Quantity</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Button
                                variant="outlined"
                                onClick={() => setCartCount(cartCount - 1 > 0 ? cartCount - 1 : 1)}
                            >
                                -
                            </Button>
                            <Typography variant="body1" sx={{ display: 'inline', marginX: 2 }}>
                                {cartCount}
                            </Typography>
                            <Button variant="outlined" onClick={() => setCartCount(cartCount + 1)}>
                                +
                            </Button>
                        </Grid>
                    </Grid>

                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ width: '100%' }}
                        onClick={handleAddToCart}
                        startIcon={<AddShoppingCart />}
                    >
                        Add to Cart
                    </Button>
                </Grid>
            </Grid>

            {/* Modal for Enlarged Image */}
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxWidth: '600px',
                        backgroundColor: 'white',
                        padding: 2,
                        boxShadow: 24,
                        borderRadius: 2,
                    }}
                >
                    <img
                        src={selectedImage}
                        alt="Enlarged Product"
                        style={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'contain',
                            cursor: 'pointer',
                        }}
                        onClick={handleCloseModal}
                    />
                </Box>
            </Modal>
        </Box>
    );
};

export default ProductDetailPage;