import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input/input'
import TextField from '@material-ui/core/TextField'
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CustomPhoneNumber from './PhoneNumber';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, updateProfile } from "firebase/auth";
import useUpdateCart from '../../Hooks/useUpdateCart/useUpdateCart';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box } from '@mui/material';
import useUserData from '../../Hooks/useUser/useUserdata';
import { Typography } from '@material-ui/core';



const Login = (props) => {
    const [mynumber, setNumber] = useState("");
    const [otp, setotp] = useState("");
    const [show, setshow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [final, setfinal] = useState("");
    const [isNewUser, setIsNewUser] = useState(false);
    const [username, setUsername] = useState('');
    const [, , , , , getCartProducts] = useUpdateCart();
    const [, , , , getCurrentUserData,] = useUserData();

    const auth = getAuth();
    auth.useDeviceLanguage();

    useEffect(() => {
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                signin();
            }
        });

    }, []);

    // const setRecaptcha = () => {
    //     window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
    //         'size': 'invisible',
    //         'callback': (response) => {
    //             // reCAPTCHA solved, allow signInWithPhoneNumber.
    //             signin();
    //         }
    //     });
    // }



    const signin = () => {
        setshow(true);
        if (mynumber === "" || mynumber.length < 10) return;
        console.log(mynumber);
        setLoading(true);
        const appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, mynumber, appVerifier)
            .then((result) => {
                setfinal(result);
                // alert("code sent");
                setshow(true);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err, 'err');
                alert(err);
                window.location.reload();
            });
    };


    const ValidateOtp = () => {
        if (otp === null || final === null) return;
        final.confirm(otp).then((result) => {
            // User signed in successfully.
            const user = result.user;

            if (!user.displayName || user.displayName === null) {
                localStorage.setItem('userData', JSON.stringify(user));
                setIsNewUser(true);
            }
            else {
                setIsNewUser(false);
                localStorage.setItem('userData', JSON.stringify(user));
                getCartProducts(user.uid);
                props.onClose(false);
            }
            // ...
        }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            // ...
        });
    };

    const updateUserDetails = () => {
        setIsNewUser(false);
        setLoading(true);
        updateProfile(auth.currentUser, {
            displayName: username,
        }).then((res) => {
            const userdata = getCurrentUserData();
            userdata.displayName = username;
            localStorage.setItem('userData', JSON.stringify(userdata));
            setLoading(false);
            getCartProducts(userdata.uid);
            props.onClose(false);
        }).catch((error) => {
            console.log("Error updating user profile", error);
            setLoading(false);
            props.onClose(false);
        });
    }

    return <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            //  alignItems: 'center',
            minHeight: '30vh',
            bgcolor: '#f5f5f5'
        }}
    >
        <Paper elevation={3} sx={{ p: 4, maxWidth: 400, minHeight: '30vh' }} >
            <Grid container columns={{ xs: 2, md: 12 }}
                style={{
                    display: isNewUser ? "none" : "block",
                }}
            >
                <div >
                    <center>
                        <Typography variant="h6" gutterBottom>
                            Login
                        </Typography>
                        <div
                        >
                            <PhoneInput
                                placeholder='Enter phone number'
                                country="IN"
                                withCountryCallingCode
                                international
                                value={mynumber}
                                onChange={setNumber}
                                inputComponent={CustomPhoneNumber}
                                style={{ marginTop: '10px', width: '300px' }}

                            />
                            <br />
                            <br />
                            <div id="recaptcha-container"></div>
                            {/* <Button onClick={signin} id="sign-in-button" variant='contained'>
                                    Send OTP
                                </Button> */}
                            <LoadingButton
                                id="sign-in-button"
                                onClick={signin}
                                loading={loading}
                                loadingIndicator="Sending..."
                                variant="contained"
                                style={{
                                    display: !show ? "block" : "none",
                                }}
                            >
                                <span>Send OTP</span>
                            </LoadingButton>

                        </div>
                        <div
                            style={{
                                display: show ? "block" : "none",
                            }}
                        >
                            <TextField
                                {...props}
                                // InputProps={{
                                //     className: classes.input
                                // }}
                                fullWidth
                                size='small'
                                label='Enter your OTP'
                                variant='outlined'
                                name='otp_number'
                                style={{ marginTop: '10px', width: '300px' }}
                                onChange={(e) => {
                                    setotp(e.target.value);
                                }}
                            />

                            <br />
                            <br />
                            <Button onClick={ValidateOtp} variant="contained">
                                Verify
                            </Button>
                        </div>
                    </center>

                </div>
            </Grid>

            <Grid container columns={{ xs: 2, md: 12 }}
                style={{
                    display: isNewUser ? "block" : "none",
                }}
            >
                <TextField
                    {...props}
                    // InputProps={{
                    //     className: classes.input
                    // }}
                    fullWidth
                    size='small'
                    label='Enter your name'
                    variant='outlined'
                    name='username'
                    style={{ marginTop: '10px', width: '300px' }}
                    onChange={(e) => {
                        setUsername(e.target.value);
                    }}
                />

                <br />
                <br />
                <Button onClick={updateUserDetails} variant="contained">
                    Update Username
                </Button>

            </Grid>
        </Paper>


    </Box>
};

export default Login;