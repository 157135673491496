import React, { Fragment, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Product from '../Product/Product';
import useProducts from '../../Hooks/useProducts/useProducts';
import LoadingSpinner from '../../Utils/loader';
import Categories from './Categories';



const Products = (props) => {
  const [productsPageData, getProducts] = useProducts();
  const { products,
    categories,
    selCat,
    isLoading,
    error } = productsPageData;

  useEffect(() => {
    getProducts();
  }, [])

  // console.log(productsPageData, productsPageData.selCat, 'productsDataFromHook1');
  return <Box sx={{ padding: 3 }}>
    <Categories categories={categories} selCat={selCat} getProducts={getProducts} />
    {/* products list */}
    {(isLoading ? <LoadingSpinner /> : <>
      <Grid container spacing={3}>
        {
          (products && products.length) ? products.map((product, index) => (

            <Product key={'pr' + index} product={product} />

          ))
            : <Grid container spacing={3}>
              There is no products available in this category.
            </Grid>
        }

      </Grid>
    </>)}
  </Box>
}

export default Products;