import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Box,
    IconButton,
    TablePagination,
    Button,
    Grid,
    useMediaQuery,
    useTheme,
    TableSortLabel,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link, useNavigate } from 'react-router-dom';
import useOrders from '../../Hooks/useOrders/useOrders';

const Orders = (props) => {
    const [ordersData] = useOrders();
    const [filter, setFilter] = useState('All');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    let orders = [];

    const onView = (orderId) => {
        console.log(`View details for order: ${orderId}`);
        navigate("/order/" + orderId);
    }
    const getProductTitle = (products) =>
        products?.map((product) => product.title) ?? [];

    const convertNumberFormat = (number) =>
        number?.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'INR',
        });

    if (Object.keys(ordersData.orders).length > 0) {
        orders = Object.values(ordersData.orders).map((order) => ({
            id: order?.orderId,
            products: getProductTitle(order?.orderDetails?.products),
            order_date: order?.orderDetails?.orderedDate,
            status: order?.orderDetails?.orderStatus,
            finalPrice: order?.orderDetails?.billingDetails?.finalPrice,
        }));
    }

    // Apply filters
    const filteredOrders =
        filter === 'All' ? orders : orders.filter((order) => order.status === filter);

    // Apply sorting
    const sortedOrders = [...filteredOrders].sort((a, b) => {
        if (!sortConfig.key) return 0;
        const order = sortConfig.direction === 'asc' ? 1 : -1;
        if (a[sortConfig.key] < b[sortConfig.key]) return -order;
        if (a[sortConfig.key] > b[sortConfig.key]) return order;
        return 0;
    });

    // Paginate the sorted and filtered orders
    const paginatedOrders = sortedOrders.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const handleSort = (key) => {
        setSortConfig((prevConfig) => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getStatusColor = (status) => {
        const colors = {
            All: '#e3f2fd', // Light Blue
            Confirmed: '#FFECB3', // Light Green
            Shipped: '#B3E5FC', // Light Yellow
            Delivered: '#C8E6C9', // Pale Green
            Cancelled: '#FFCDD2', // Light Red/Orange
        };
        return colors[status] || '#f0f0f0'; // Default Light Gray
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h4" gutterBottom>
                My Orders
            </Typography>

            {/* Filter Tiles */}
            <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                {['All', 'Confirmed', 'Shipped', 'Delivered', 'Cancelled'].map((status) => (
                    <Grid item key={status}>
                        <Button
                            variant="contained"
                            size="medium"
                            color={filter === status ? 'primary' : 'inherit'}
                            sx={{
                                backgroundColor: filter === status ? 'primary.main' : getStatusColor(status),
                                color: filter === status ? '#fff' : '#000',
                            }}
                            onClick={() => setFilter(status)}
                        >
                            {status}
                        </Button>
                    </Grid>
                ))}
            </Grid>

            {filteredOrders.length === 0 ? (
                <Box sx={{ textAlign: 'center', marginTop: 5 }}>
                    <Typography variant="h6" color="textSecondary" gutterBottom>
                        No orders found.
                    </Typography>
                    <Button variant="contained" color="primary" component={Link} to="/products">
                        Go to Products Page
                    </Button>
                </Box>
            ) : (
                <>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow >
                                    <TableCell>#</TableCell>
                                    <TableCell sortDirection={sortConfig.key === 'id' ? sortConfig.direction : false}>
                                        <TableSortLabel
                                            active={sortConfig.key === 'id'}
                                            direction={sortConfig.key === 'id' ? sortConfig.direction : 'asc'}
                                            onClick={() => handleSort('id')}
                                        >
                                            <strong> Order ID</strong>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sortDirection={sortConfig.key === 'order_date' ? sortConfig.direction : false}>
                                        <TableSortLabel
                                            active={sortConfig.key === 'order_date'}
                                            direction={sortConfig.key === 'order_date' ? sortConfig.direction : 'asc'}
                                            onClick={() => handleSort('order_date')}
                                        >
                                            Date
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Products</TableCell>
                                    <TableCell sortDirection={sortConfig.key === 'finalPrice' ? sortConfig.direction : false}>
                                        <TableSortLabel
                                            active={sortConfig.key === 'finalPrice'}
                                            direction={sortConfig.key === 'finalPrice' ? sortConfig.direction : 'asc'}
                                            onClick={() => handleSort('finalPrice')}
                                        >
                                            Total Price
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedOrders.map((order, index) => (
                                    <TableRow key={index} sx={{
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)', // Light gray background on hover
                                            cursor: 'pointer', // Pointer cursor on hover
                                        },
                                    }}>
                                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                        <TableCell>{order.id}</TableCell>
                                        <TableCell>{new Date(order.order_date).toLocaleDateString()}</TableCell>
                                        <TableCell>{order.products.join(', ')}</TableCell>
                                        <TableCell>
                                            {convertNumberFormat(order.finalPrice)}
                                        </TableCell>
                                        <TableCell>{order.status}</TableCell>
                                        <TableCell align="center">
                                            <IconButton
                                                color="primary"
                                                onClick={() => onView(order.id)}
                                            >
                                                <VisibilityIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        component="div"
                        count={filteredOrders.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 15]}
                    />
                </>
            )}
        </Box>
    );
};

export default Orders;