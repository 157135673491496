import React, { useState, Fragment } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Button from '@mui/material/Button';
import useUpdateCart from '../../Hooks/useUpdateCart/useUpdateCart';
import Toast from '../../Utils/toast';
import useUserData from '../../Hooks/useUser/useUserdata';
import { Grid } from '@mui/material';
import { Box, IconButton } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const Product = (props) => {
  const [quantity, setQuantity] = useState(1);
  const [isAddedTocart, setIsAddedTocart] = useState(false);
  const { product } = props;
  const [cartData, addProduct] = useUpdateCart();
  const [, showLoginDrawer, , isUserAutheticated, getCurrentUserData] = useUserData();
  const navigate = useNavigate(); // Hook to navigate to different routes

  const handleAddProduct = (product) => {
    if (isUserAutheticated() == true) {
      const userDataObj = getCurrentUserData();
      product.quantity = quantity;
      addProduct([cartData.products, product, userDataObj.uid]);
      setIsAddedTocart(true);
      setQuantity(1);
    } else {
      showLoginDrawer(true);
    }

  }

  const handleCardClick = (productId) => {
    navigate(`/products/${productId}`); // Navigate to the product detail page
  };
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          cursor: "pointer",
        }}
        onClick={() => handleCardClick(product.id)} // Handle the click to navigate
      >
        {/* Product Image */}
        <CardMedia
          component="img"
          sx={{ height: 250, objectFit: "contain" }}
          image={product.images[0]}
          // image={require(`../../images/${product.images[0]}`)}
          alt={product.title}
        />
        {/* Product Details */}
        <CardContent
          sx={{
            flexGrow: 1,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}>

          <Typography gutterBottom variant="h6" component="div">
            {product.title}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            {product.description}
          </Typography>
          <Typography variant="h6" color="primary">
            ${product.price}
          </Typography>
        </CardContent>

        {/* Product Actions */}
        <CardActions sx={{
          justifyContent: "space-between",
          padding: "0 16px 16px",
        }}>



          <Box>
            <IconButton variant="outlined" size="small" color="success" onClick={() => { setQuantity(quantity => quantity - 1) }} disabled={quantity <= 1}>
              <RemoveIcon />
            </IconButton>

            <Typography variant="h6" component="span" size="small" sx={{ mx: 1 }} color="grey" >
              {quantity}
            </Typography>

            <IconButton variant="outlined" size='small' color="success" onClick={() => { setQuantity(quantity => quantity + 1) }}>
              <AddIcon />
            </IconButton>
          </Box>
          <Button variant="outlined" size='small' color='success' onClick={() => { handleAddProduct(product) }}>
            Add
          </Button>

          {/* <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}

        </CardActions>
      </Card>
      <Toast open={isAddedTocart} message={'Product Added To Cart!'} type={'success'} />
    </Grid>
  );
}

export default Product;