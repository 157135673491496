import React from 'react';
import { useSelector } from 'react-redux';
import {
    Paper,
    Box,
    Grid,
    Button,
    Avatar,
    Card,
    CardContent,
    Divider,
    Typography,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import useUpdateCart from '../../Hooks/useUpdateCart/useUpdateCart';

const FinalBilling = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { billingDetails } = useSelector((state) => state.useUpdateCartReducer);
    const { selectedAddress } = useSelector((state) => state.useUserdataReducer);
    const [cartData] = useUpdateCart();
    const cartItems = [...cartData.products];
    const address = selectedAddress;

    const convertNumberFormat = (number) => {
        return number.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'INR',
        });
    };

    return (
        <Box sx={{ padding: isMobile ? 2 : 4 }}>
            <Typography variant="h4" gutterBottom>
                Review Your Order
            </Typography>

            {/* Order Items */}
            <Typography variant="h5" gutterBottom>
                Order Items
            </Typography>
            <Grid container spacing={2}>
                {cartItems &&
                    cartItems.length > 0 &&
                    cartItems.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card variant="outlined" sx={{ padding: 2 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar
                                        src={item.images[0]}
                                        alt={item.title}
                                        sx={{ width: 80, height: 80, marginRight: 2 }}
                                    />
                                    <Box>
                                        <Typography variant="h6">{item.title}</Typography>
                                        <Typography>Quantity: {item.quantity}</Typography>
                                        <Typography>Price: {convertNumberFormat(item.price)}</Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
            </Grid>

            <Divider sx={{ marginY: 4 }} />

            {/* Billing Details */}
            <Typography variant="h5" gutterBottom>
                Billing & Pricing Details
            </Typography>
            <Card variant="outlined" sx={{ marginBottom: 2 }}>
                <CardContent>
                    <Typography>
                        <strong>Name:</strong> {billingDetails?.name}
                    </Typography>
                    <Typography>
                        <strong>Phone:</strong> {billingDetails?.mobile}
                    </Typography>
                    <Divider sx={{ marginY: 2 }} />
                    <Typography variant="h6" gutterBottom>
                        Pricing Summary
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1 }}>
                        <Typography>Subtotal:</Typography>
                        <Typography>{convertNumberFormat(billingDetails.totalPrice)}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1 }}>
                        <Typography>Discount:</Typography>
                        <Typography>- {convertNumberFormat(billingDetails.discount)}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1 }}>
                        <Typography>Delivery Charges:</Typography>
                        <Typography>{convertNumberFormat(billingDetails.deliveryCharges)}</Typography>
                    </Box>
                    <Divider sx={{ marginY: 1 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                        <Typography>Total:</Typography>
                        <Typography>{convertNumberFormat(billingDetails.finalPrice)}</Typography>
                    </Box>
                </CardContent>
            </Card>

            {/* Address Block */}
            <Paper elevation={3} sx={{ padding: isMobile ? 2 : 3 }}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={8}>
                        <Typography variant="h5">Address Details</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: 'right' }}>
                        <Button variant="contained" onClick={props.changeAddress}>
                            Change
                        </Button>
                    </Grid>
                </Grid>
                <Box sx={{ marginTop: 2 }}>
                    <Typography>{address.address_type}</Typography>
                    <Typography>{address.flat_no},</Typography>
                    <Typography>{address.location},</Typography>
                    <Typography>{address.city},</Typography>
                    <Typography>{address.state},</Typography>
                    <Typography>{address.pincode}</Typography>
                </Box>
            </Paper>
        </Box>
    );
};

export default FinalBilling;