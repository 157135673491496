import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import MaterialFormComponent from "./NewAddressForm";
import useUserData from "../../Hooks/useUser/useUserdata";
import AddressCard from "./AddressCard";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import RadioGroup from '@mui/material/RadioGroup';
import FinalBilling from "./FinalBilling";
import useOrders from "../../Hooks/useOrders/useOrders";
import LoadingSpinner from "../../Utils/loader";
import { useNavigate } from 'react-router-dom';
import { Typography } from "@material-ui/core";


const AddressBlock = (props) => {
    const [showNewAddress, setshowNewAddress] = useState(false);
    const [addressSelected, setaddressSelected] = useState({});
    const [isAddressSelected, setisAddressSelected] = useState(false);
    const navigate = useNavigate();

    const [result,
        ,
        ,
        ,
        getCurrentUserData,
        ,
        updateUserAddress,
        getuserAddress, setSelectedAddress] = useUserData();
    const [orderDetails, createOrder, getUserOrders] = useOrders();

    console.log(result, 'userData');

    useEffect(() => {
        if (orderDetails.isOrderCreated) {
            const userDataObj = getCurrentUserData();
            getUserOrders(userDataObj.uid);
            navigate("/orders");
        }
    }, [orderDetails.isOrderCreated]);
    useEffect(() => {
        const userData = getCurrentUserData();
        getuserAddress(userData.uid);
    }, []);

    const { selectedAddress, userAddress, } = result;

    const handleAddressChange = (value) => {
        console.log(value, 'event ');
        setaddressSelected(value);
        setSelectedAddress(value);
        // setisAddressSelected(true);
        setshowNewAddress(false);
    }

    const handleChangeDeliveryAddress = () => {
        setisAddressSelected(false);
        setshowNewAddress(false);

    }

    const handleCancelButton = () => {
        setshowNewAddress(false);
    }

    const renderButton = () => {
        return <>{
            (!showNewAddress) ? <Button
                variant="outlined"
                color="success"
                onClick={() => setshowNewAddress(true)}>
                Add New Address
            </Button> : null}</>
    }

    const renderAddress = (userAddress) => {
        return <Box sx={{ flexGrow: 1, pt: 2 }}>
            <Typography variant="h6" gutterBottom>
                Select Shipping Address
            </Typography>
            <Grid container spacing={2}>

                {userAddress.map(address => <AddressCard
                    address={address}
                    selectedAddress={selectedAddress ? selectedAddress : addressSelected}
                    handleAddressChange={handleAddressChange}
                />)}

            </Grid>
        </Box >
    }
    return <Box sx={{ padding: 3 }}>
        {
            (showNewAddress) ? <div>

                <MaterialFormComponent
                    addUser={updateUserAddress}
                    getUserAddress={getuserAddress}
                    userAddresses={result.userAddress}
                    getCurrentUserData={getCurrentUserData}
                    onCancel={handleCancelButton}

                /> </div> : null
        }

        {
            (!showNewAddress && !isAddressSelected && (userAddress && userAddress.length)) ? renderAddress(userAddress) : null
        }
        <Grid sx={{ marginTop: 4 }}>
            {renderButton()}
        </Grid>
    </Box>


}

export default AddressBlock;