import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Cart from './Cart';
import AddressBlock from './Address';
import FinalBilling from './FinalBilling';
import useUserData from '../../Hooks/useUser/useUserdata';
import useOrders from '../../Hooks/useOrders/useOrders';
import { useSelector, useDispatch } from 'react-redux';
import LoadingSpinner from '../../Utils/loader';
import SuccessView from './Success';


const steps = ['Cart', 'Address', 'Review', 'Ordered'];

export default function HorizontalLinearStepper() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [stepText, setStepText] = React.useState('Select Delivery Address');

    const [result,
        showLoginDrawer,
        hideLoginDrawer,
        isUserAutheticated,
        getCurrentUserData,
        logout,
        updateUserAddress,
        getuserAddress, setSelectedAddress] = useUserData();
    const [orderDetails, createOrder, getUserOrders] = useOrders();
    const cartData = useSelector(state => state.useUpdateCartReducer);

    console.log(result, orderDetails, cartData, 'from stepper');

    const handleCreateOrder = () => {
        console.log(cartData);
        const userDataObj = getCurrentUserData();
        const OrderDetails = {
            products: cartData.products,
            billingDetails: cartData.billingDetails,
            UserId: userDataObj.uid,
            deliveryAddress: result.selectedAddress,
            userContact: userDataObj.phoneNumber,
            orderStatus: 'Confirmed',
            orderedDate: new Date().toJSON()
        }
        createOrder([OrderDetails, userDataObj.uid,]);
        // handleNext();
    }

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    React.useEffect(() => {
        if (activeStep == 0) {
            setStepText('Select Delivery Address')
        }
        if (activeStep == 1) {
            setStepText('Review Order')
        }
        if (activeStep == 2) {
            setStepText('Create Order')
        }
        if (activeStep == 3) {
            setStepText('Finish')
        }
    }, [activeStep]);

    React.useEffect(() => {
        if (orderDetails.isOrderCreated) {
            const userDataObj = getCurrentUserData();
            getUserOrders(userDataObj.uid);
            // navigate("/orders");
        }
    }, [orderDetails.isOrderCreated]);

    const checkIsDiabled = () => {
        if (activeStep == 1) {
            if (Object.keys(result.selectedAddress).length) {
                return false;
            } else {
                return true;
            }
        } else {
            return false
        }

    }

    const handleNext = () => {

        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        if (activeStep == 2) {
            handleCreateOrder();
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const renderContent = (activeStep) => {
        switch (activeStep) {
            case 0: return <Cart />;
            case 1: return <AddressBlock />;
            case 2: return <FinalBilling changeAddress={handleBack} />;
            default: return <> {orderDetails.isLoading ? <LoadingSpinner /> : <SuccessView />} </>
        }
    }

    return (
        <Box sx={{ width: '100%', pt: 2 }}>
            <Stepper activeStep={activeStep} >
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps} >
                            <StepLabel {...labelProps}
                            >{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>

                        All steps completed - you&apos;re finished

                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {/* code for content */}
                    {renderContent(activeStep)}
                    {/* code for first to last but one here */}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, visibility: (cartData?.products && cartData?.products?.length) ? 'show' : 'hidden' }} >
                        <Button
                            color="inherit"
                            variant='outlined'
                            disabled={activeStep === 0 || activeStep === 3}
                            style={{ display: (activeStep === 3) ? 'none' : 'block' }}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />

                        <Button variant='outlined' style={{ display: (activeStep === 3) ? 'none' : 'block' }}
                            disabled={checkIsDiabled()}
                            onClick={handleNext} >
                            {stepText}
                        </Button>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
}