import React, { Fragment, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CartProduct from './Cartproduct';
import useUpdateCart from '../../Hooks/useUpdateCart/useUpdateCart';
import Billing from './Billing';
import { NavLink } from "react-router-dom";
import AddressBlock from './Address';
import Button from '@mui/material/Button';
import HorizontalLinearStepper from './stepper';

const Cart = (props) => {
  const [cartData] = useUpdateCart();
  const cartItems = [...cartData.products];
  return <Grid container spacing={3} style={{ marginTop: '30px', padding: '5px' }}>
    {
      (cartData.count) ? <>
        <Grid item xs={12} md={8}>
          {cartItems && cartItems.map((product, index) => (
            <Grid item xs={12} sm={12} md={12} key={index}>
              <CartProduct key={'ctpr' + index} product={product} />
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} md={4}>
          <Billing />
        </Grid>

      </> : <div> No Items Availabe in your cart. go to <NavLink className="" to="/products">Products</NavLink> Page.</div>
    }
  </Grid>
}

export default Cart;