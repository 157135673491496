import React from 'react';
import { Box, Container, Grid, Link, Typography, IconButton } from '@mui/material';
import { Facebook, Instagram, Twitter, YouTube } from '@mui/icons-material';

const Footer = () => {
    return (
        <Box
            sx={{
                backgroundColor: '#333',
                color: 'white',
                py: 3,
                mt: 5,
            }}
        >
            <Container maxWidth="lg">
                {/* Footer Main Content */}
                <Grid container spacing={3}>

                    {/* Menu Section */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            Menu
                        </Typography>
                        <Box>
                            <Link href="#" color="inherit" sx={{ display: 'block', mb: 1 }}>
                                Home
                            </Link>
                            <Link href="#" color="inherit" sx={{ display: 'block', mb: 1 }}>
                                Shop
                            </Link>
                            <Link href="#" color="inherit" sx={{ display: 'block', mb: 1 }}>
                                Blog
                            </Link>
                            <Link href="#" color="inherit" sx={{ display: 'block', mb: 1 }}>
                                About Us
                            </Link>
                        </Box>
                    </Grid>

                    {/* Help Section */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            Help
                        </Typography>
                        <Box>
                            <Link href="#" color="inherit" sx={{ display: 'block', mb: 1 }}>
                                Privacy Policy
                            </Link>
                            <Link href="#" color="inherit" sx={{ display: 'block', mb: 1 }}>
                                Terms of Service
                            </Link>
                            <Link href="#" color="inherit" sx={{ display: 'block', mb: 1 }}>
                                FAQs
                            </Link>
                            <Link href="#" color="inherit" sx={{ display: 'block', mb: 1 }}>
                                Return Policy
                            </Link>
                        </Box>
                    </Grid>

                    {/* Address & Contact Section */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            Contact Us
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            9-175 ,Oppt. MRO Office,
                            Kakinada, AP, India
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Email: info@hygienebites.com
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Phone: +91 9666 628
                            477
                        </Typography>
                    </Grid>

                </Grid>

                {/* Social Media Section */}
                <Grid container justifyContent="center" sx={{ mt: 2 }}>
                    <IconButton href="https://twitter.com/hygienebite" target="_blank" color="inherit" sx={{ mx: 1 }}>
                        <Twitter />
                    </IconButton>
                    <IconButton href="https://facebook.com/hygienebite" target="_blank" color="inherit" sx={{ mx: 1 }}>
                        <Facebook />
                    </IconButton>
                    <IconButton href="https://instagram.com/hygienebite" target="_blank" color="inherit" sx={{ mx: 1 }}>
                        <Instagram />
                    </IconButton>
                    <IconButton href="https://youtube.com/hygienebite" target="_blank" color="inherit" sx={{ mx: 1 }}>
                        <YouTube />
                    </IconButton>
                </Grid>

                {/* Copyright Strip */}
                <Box sx={{ backgroundColor: '#222', color: '#bbb', py: 1, mt: 3 }}>
                    <Typography variant="body2" align="center">
                        &copy; {new Date().getFullYear()} hygienebites. All Rights Reserved.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;