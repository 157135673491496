import React, { useState } from "react";
import {
  Box,
  Drawer,
  IconButton,
  makeStyles,
  Typography
} from "@material-ui/core";
import Login from "./Login";
import Grid from '@mui/material/Grid';
import useUserData from "../../Hooks/useUser/useUserdata";
import CloseIcon from '@mui/icons-material/Close';




const useStyles = makeStyles(() => ({
  link: {
    textDecoration: "none",
    color: "#d99308",
    fontSize: "20px",
  },
  icon: {
    color: "white"
  }
}));

const LoginDrawerComponent = (props) => {
  const [userData, showLoginDrawer, hideLoginDrawer] = useUserData();
  console.log(userData);
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(true);
  return (
    <>
      <Drawer
        open={userData.showDrawer}
        onClose={() => hideLoginDrawer(false)}
        anchor="right"

      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',

            marginBottom: 2,
          }}
        >
          <IconButton
            onClick={hideLoginDrawer}
            sx={{ color: 'black' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: 350,
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Login onClose={hideLoginDrawer} />
        </Box>
      </Drawer >
    </>
  );
}
export default LoginDrawerComponent;