import React, { useState } from "react";
import {
    Grid,
    Paper,
    Box,
    Badge,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    TablePagination,
} from "@mui/material";

const OrdersDashboard = () => {
    const [activeTile, setActiveTile] = useState("Orders");
    const [newCounts, setNewCounts] = useState({ Orders: 2, Pending: 1, Cancelled: 0, Delivered: 3 });
    const [orders] = useState([
        { id: 1, customer: "John Doe", status: "Delivered", amount: "$100" },
        { id: 2, customer: "Jane Smith", status: "Pending", amount: "$200" },
        { id: 3, customer: "Sam Wilson", status: "Cancelled", amount: "$50" },
        { id: 4, customer: "Lisa Ray", status: "Pending", amount: "$300" },
        { id: 5, customer: "Mark Lee", status: "Delivered", amount: "$150" },
        { id: 6, customer: "Emily White", status: "Delivered", amount: "$80" },
        { id: 7, customer: "Peter Black", status: "Pending", amount: "$120" },
    ]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);

    const getFilteredOrders = () => {
        if (activeTile === "Pending") return orders.filter((order) => order.status === "Pending");
        if (activeTile === "Cancelled") return orders.filter((order) => order.status === "Cancelled");
        if (activeTile === "Delivered") return orders.filter((order) => order.status === "Delivered");
        return orders;
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const displayedOrders = getFilteredOrders().slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const tileStyles = {
        Orders: { backgroundColor: "#90caf9", color: "#0d47a1" },
        Pending: { backgroundColor: "#ffe082", color: "#ff6f00" },
        Cancelled: { backgroundColor: "#ef9a9a", color: "#b71c1c" },
        Delivered: { backgroundColor: "#a5d6a7", color: "#1b5e20" },
    };

    return (
        <Box sx={{ padding: 3 }}>
            {/* Tiles Section */}
            <Grid container spacing={3}>
                {["Orders", "Pending", "Cancelled", "Delivered"].map((tile, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Paper
                            onClick={() => setActiveTile(tile)}
                            sx={{
                                padding: 2,
                                textAlign: "center",
                                cursor: "pointer",
                                ...tileStyles[tile],
                                boxShadow: activeTile === tile ? "0px 4px 20px rgba(0, 0, 0, 0.2)" : "",
                            }}
                        >
                            <Badge
                                color="error"
                                badgeContent={newCounts[tile]}
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                            >
                                <Typography variant="h6">{tile}</Typography>
                            </Badge>
                            <Typography variant="h4">
                                {tile === "Orders"
                                    ? orders.length
                                    : tile === "Pending"
                                        ? orders.filter((order) => order.status === "Pending").length
                                        : tile === "Cancelled"
                                            ? orders.filter((order) => order.status === "Cancelled").length
                                            : orders.filter((order) => order.status === "Delivered").length}
                            </Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>

            {/* Orders Table */}
            <Box sx={{ marginTop: 3 }}>
                <Typography variant="h5" sx={{ marginBottom: 2 }}>
                    {activeTile} List
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Customer</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedOrders.map((order) => (
                            <TableRow key={order.id}>
                                <TableCell>{order.id}</TableCell>
                                <TableCell>{order.customer}</TableCell>
                                <TableCell>{order.status}</TableCell>
                                <TableCell>{order.amount}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={getFilteredOrders().length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </Box>
    );
};

export default OrdersDashboard;