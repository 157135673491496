import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { NavLink } from 'react-router-dom';

const Categories = (props) => {
    const { selCat, categories, getProducts } = props;
    const handleChange = (event, newValue) => {
        getProducts(newValue);
    };
    return <Fragment>
        <Box sx={{ marginBottom: 3 }}
        >
            <Tabs value={selCat} onChange={handleChange} scrollButtons="auto"
                variant="scrollable"
                aria-label="Categories"
                sx={{ paddingBottom: 1 }}>
                {
                    categories && categories.map((cat, index) => {
                        return <Tab value={cat} label={cat?.name} key={cat?.id} />
                    })
                }
            </Tabs>
        </Box>
    </Fragment>
}
export default Categories;