import React from "react";
import {
  // BrowserRouter as Router, 
  Route,
  // Link, 
  Routes
} from "react-router-dom";
import Home from "./Components/Home/home";
import Products from "./Components/Products/Products";
import Product from "./Components/Product/Product";
import Orders from "./Components/Orders/Orders";
import Blog from "./Components/Blog/Blog";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import Cart from "./Components/Cart/Cart";
import Login from "./Components/Login/Login";
import NewCart from "./Components/Cart/NewCart";
import Order from "./Components/Order/Order";
import Dashboard from "./Components/Admin/Dashboard/Dashboard";
import ProductsList from "./Components/Products/productList";
// import ProductDetailPage12 from "./Components/Cart/test";
import ProductDetailPage from "./Components/ProductDetail/ProductDetail";


const routes = (
  <Routes>
    <Route exact path="/" Component={Products} />
    <Route path="/products" Component={Products} />
    {/* <Route path="/product" Component={Product} /> */}
    <Route path="/products/:productId" Component={ProductDetailPage} />
    <Route path='/Cart' Component={NewCart} />
    {/* <Route path='/test' Component={ProductDetailPage12} /> */}
    <Route path="/orders" Component={Orders} />
    <Route path="/order/:id" Component={Order} />
    <Route path="/blog" Component={Blog} />
    <Route path="/about-us" Component={About} />
    <Route path="/contact-us" Component={Contact} />
    <Route path="/Login" Component={Login} />
    <Route path="/admin/dashboard" Component={Dashboard} />
  </Routes>
);

export default routes;
