import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    TextField,
    MenuItem,
    Select,
    FormGroup,
    Checkbox,
    Button
} from '@mui/material';
import { Grid } from '@material-ui/core';

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);


export default function AddressCard(props) {
    const { address, selectedAddress } = props;
    return (<Grid item xs={12} sm={6} md={4} key={address.id}>
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: 2,
                boxShadow: 3,
                borderRadius: 5, // Added border radius
                border: selectedAddress?.flat_no === address?.flat_no ? '2px solid #1976d2' : '1px solid #ccc',
                transition: 'box-shadow 0.3s ease',
                '&:hover': {
                    boxShadow: 6,
                },
            }}
        >
            <CardContent>
                {/* Address Title */}
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    {address?.address_type}
                </Typography>
                {/* Address Details */}
                <Typography variant="body2"> {address?.first_name} {address?.last_name} ,</Typography>
                <Typography variant="body2"> {address?.flat_no} ,</Typography>
                <Typography variant="body2"> {address?.location},,  {address?.city}  {address?.state}</Typography>
                <Typography variant="body2">{address?.pincode}</Typography>

                {/* Radio Button to select the address */}
                <FormControlLabel
                    // value={address.flat_no}
                    control={
                        <Radio
                            checked={selectedAddress?.flat_no === address?.flat_no}
                            // onChange={handleAddressSelection}
                            value={address?.flat_no}
                            name="address"
                            // value={address.flat_no}
                            onChange={() => props.handleAddressChange(address)}
                        />
                    }
                    label="Select Address"
                    sx={{ marginTop: 2 }}
                />
            </CardContent>
        </Card>
    </Grid>
    );
}