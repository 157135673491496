import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { Divider } from '@material-ui/core';


const Billing = (props) => {
    const { billingDetails } = useSelector(state => state.useUpdateCartReducer);
    const convertNumberFormat = (number) => {
        return number.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'INR'
        });
    }
    return <Fragment>

        <Box
            sx={{
                padding: 2,
                border: '1px solid #ccc',
                borderRadius: 1,
                backgroundColor: '#f9f9f9',
            }}
        >
            <Typography variant="h6" gutterBottom>
                Billing Details
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="body1" sx={{ mb: 1 }}>
                Subtotal: <strong>{convertNumberFormat(billingDetails.totalPrice)}</strong>
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
                Discount: <strong>-{0}</strong>
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
                Delivery Fee: <strong> {convertNumberFormat(billingDetails.deliveryCharges)}</strong>
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="h6">
                Total: <strong>  {convertNumberFormat(billingDetails.finalPrice)}</strong>
            </Typography>
            {/* <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
            >
                Proceed to Checkout
            </Button> */}
        </Box>


    </Fragment>
}

export default Billing;